const creds = {
    // backendUrl: "/api/", // Default API call end point.
     //backendUrl: process.env.REACT_APP_BASE_URL // Local development API end point.
    // backendUrl: 'https://neighborhood.com/api/', // Production API end point.
     backendUrl: 'https://transliteration.ebslab.in/api/',
    //backendUrl: 'http://127.0.0.1:5000/api/',
  };
  
  export const url = creds.backendUrl;
  export default creds;
  